<template>
  <div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          show-empty
          :items="items"
          :fields="fields"
          class="mb-0 ban-list-table"
        >
          <template #head(account)>
            <span v-text="$t('account')" />
          </template>
          <template #head(memo)>
            <span v-text="$t('begin_time')" />
          </template>
          <template #head(create_time)>
            <span v-text="$t('create_time')" />
          </template>
          <template #head(action)>
            <span v-text="$t('action')" />
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="unban(data.item)"
              v-text="$t('unban')"
            />
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BTable,
  BPagination,
  BButton,
} from 'bootstrap-vue'

import Account from '@/models/Account'

import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'BanList',
  components: {
    BOverlay,
    BCard,
    BTable,
    BPagination,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      fields: [
        {
          key: 'account',
        },
        {
          key: 'memo',
        },
        {
          key: 'create_time',
        },
        {
          key: 'action',
        },
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.banList(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    async unban(data) {
      try {
        const response = await Account.unban({ userId: data.user_id })
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>
